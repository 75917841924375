import { isActiveStatus } from '#billing/utils'

export default defineNuxtRouteMiddleware(to => {
  const { subscription, getBillingUrl } = useWorkspaceSubscription()
  const { currentWorkspace } = useWorkspaceSharedState()

  if (!currentWorkspace.value || subscription.value === false || subscription.value === undefined) {
    // No workspace or subscription data yet
    return
  }

  if (!subscription.value || (subscription.value && !isActiveStatus(subscription.value?.status))) {
    const billingUrl = getBillingUrl()
    if (!to.path.startsWith(billingUrl)) {
      return navigateTo(getBillingUrl())
    }
  }
})
